function hideAnnouncement(announcement_key) {
  createCookie(announcement_key, "hidden", 365);
  document.getElementById("announcement").style.display = 'none'
}

// Utility script from http://www.quirksmode.org/js/cookies.html
function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

window.hideAnnouncement = hideAnnouncement
